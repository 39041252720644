import axios from 'axios'
import { Toast } from 'vant'
// const baseUrl = process.env.VUE_APP_BASE_URL

const request = axios.create({
  // baseURL: baseUrl,
  timeout: 15000,
  retry: 3,
  retryDelay: 3000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 添加请求拦截器
request.interceptors.request.use(
  config => {
    config.headers.sessionID = localStorage.getItem('sessionID') || 'MINI'
    const appId = localStorage.getItem('appId')
    if (appId !== 'undefined' && appId !== 'null' && appId !== null && appId !== undefined) {
      config.headers.wxAppID = appId
    }
    return config
  },
  err => {
    Toast('网络请求失败，请稍后重试')
    return Promise.reject(err)
  }
)
// 添加响应拦截器
request.interceptors.response.use(
  res => {
    if (res.data.result >= 0) {
      return Promise.resolve(res.data)
    } else if (res.data.result === -4) {
      Toast('用户未登录，请登录后再试')
      return Promise.reject(res.data)
    } else {
      Toast(res.data.message)
      return Promise.reject(res.data)
    }
  },
  err => {
  // 超时处理 err.config是一个对象，包含上方create中设置的三个参数
    const config = err.config
    if (!config || !config.retry) {
      Toast(err.message)
      return Promise.reject(err)
    }

    // __retryCount用来记录当前是第几次发送请求
    config.__retryCount = config.__retryCount || 0

    // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
    if (config.__retryCount >= config.retry) {
      Toast(err.message)
      return Promise.reject(err)
    }

    // 记录请求次数+1
    config.__retryCount += 1

    // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
    const backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 100)
    })

    // 再次发送请求
    return backoff.then(function () {
      return request(config)
    })
  })

const requestLogin = axios.create({
  timeout: 15000,
  retry: 3,
  retryDelay: 3000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 添加请求拦截器
requestLogin.interceptors.request.use(
  config => {
    config.headers.sessionID = 'MINI'
    return config
  },
  err => {
    Toast('网络请求失败，请稍后重试')
    return Promise.reject(err)
  }
)
// 添加响应拦截器
requestLogin.interceptors.response.use(
  res => {
    if (res.data.result >= 0) {
      return Promise.resolve(res.data)
    } else {
      Toast(res.data.message)
      return Promise.reject(res.data)
    }
  },
  err => {
    // 超时处理 err.config是一个对象，包含上方create中设置的三个参数
    const config = err.config
    if (!config || !config.retry) {
      Toast(err.message)
      return Promise.reject(err)
    }

    // __retryCount用来记录当前是第几次发送请求
    config.__retryCount = config.__retryCount || 0

    // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
    if (config.__retryCount >= config.retry) {
      Toast(err.message)
      return Promise.reject(err)
    }

    // 记录请求次数+1
    config.__retryCount += 1

    // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
    const backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 100)
    })

    // 再次发送请求
    return backoff.then(function () {
      return request(config)
    })
  }
)
export {
  request,
  requestLogin
}
