import {
  request,
  requestLogin
} from './request'

// 获取商品详情
export function productShopDetail (data) {
  return request({
    url: '/centerCore/productMain/productShopDetail',
    method: 'GET',
    params: data
  })
}
// 加入常购清单
export function addOften (data) {
  return request({
    url: '/centerCore/mchAlwaysBuyProduct/insertSelective',
    method: 'POST',
    data
  })
}
// 加入购物车
export function addShoppingCat (data) {
  return request({
    url: '/centerCore/shoppingCart/addShoppingCat',
    method: 'POST',
    data
  })
}
// 查看地址
export function selectShoppingCartDefaultAddress () {
  return request({
    url: '/centerCore/shoppingCart/selectShoppingCartDefaultAddress',
    method: 'GET'
  })
}
// 获取结算页
export function selectCatAndClear (data) {
  return request({
    url: '/centerCore/shoppingCart/selectCatAndClear',
    method: 'POST',
    data
  })
}
// 获取支付方式
export function getPaymentType (data) {
  return request({
    url: '/centerCalculate/paymentType/getPaymentType',
    method: 'POST',
    data
  })
}
// 下单创建
export function commonOrderCreate (data) {
  return request({
    url: '/centerCore/orderMain/commonOrderCreate',
    method: 'POST',
    data
  })
}
// 建立商流关系
export function establishBusinessRelationship (data) {
  return request({
    url: '/base/shopDpt/establishBusinessRelationship',
    method: 'GET',
    params: data
  })
}
// 查询用户资料填写状态
export function dptCommonVerify () {
  return request({
    url: '/base/dpt/dptCommonVerify',
    method: 'POST'
  })
}
// 积分模块_订单相关_使用积分
export function selectOrdIsUsingCumScore (data) {
  return request({
    url: '/base/dptCumScore/selectOrdIsUsingCumScore',
    method: 'POST',
    data
  })
}
// 积分模块_订单相关_获取积分规则
export function queryDptCumScoreRule (data) {
  return request({
    url: '/base/dptCumScoreRule/queryDptCumScoreRule',
    method: 'POST',
    data
  })
}
// H5调取跳转
export function qrCodeJump (data) {
  return requestLogin({
    url: '/base/baseQrCodeApply/qrCodeJump',
    method: 'POST',
    data
  })
}
// 购物车角标
export function shoppingCartIcon (data) {
  return request({
    url: '/centerCore/shoppingCart/shoppingCartIcon',
    method: 'GET',
    params: data
  })
}
// 小程序数据查询
export function getwxmindata (data) {
  return request({
    url: '/centerCore/orderMain/getwxmindata',
    method: 'POST',
    data
  })
}
// 商城商品添加一条催货记录
export function insertShopProUrge (data) {
  return request({
    url: '/centerCore/productMain/insertShopProUrge',
    method: 'POST',
    data
  })
}
// 跳转抖音
export function junpdy (data) {
  return request({
    url: '/base/dy/junpdy',
    method: 'get',
    params: data
  })
}
// 跳转抖音
export function getShortUrl (data) {
  return request({
    url: '/base/dy/getShortUrl',
    method: 'get',
    params: data
  })
}
// 跳转抖音
export function getShortUrl8 (data) {
  return request({
    url: '/base/dy/getShortUrl8',
    method: 'get',
    params: data
  })
}

// `customer_address` varchar(100) DEFAULT NULL COMMENT '客户地址',
// `customer_contact_user` varchar(30) DEFAULT NULL COMMENT '客户联系人',
// `customer_contact_phone` varchar(20) DEFAULT NULL COMMENT '客户联系人电话',
// `delivery_remarks` varchar(100) DEFAULT NULL COMMENT '备注',
// `mid` bigint(20) DEFAULT NULL COMMENT '商户id',
// "shopId": shopName,
// dptDeliveryNoteProAmt onlinePrice*productNum
// dptDeliveryNoteProPrice onlinePrice
// dptDeliveryNoteProCostPrice onlinePrice
